import logo from './programpirates.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Coming Soon..</p>
        <h1>Contact Us:</h1>
        <p>programpirates@gmail.com</p>
        <p>Join our Discord: https://discord.gg/G9H674bFWQ </p>
      </header>
    </div>
  );
}

export default App;
